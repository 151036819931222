import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import DeckSelector from '../../components/decks/DeckSelector';
import InfoHint from '../common/InfoHint';
import { Link } from 'react-router-dom';
import { useDeckService } from '../../services/deckService';
import { useAdminDeckService } from '../../services/adminDeckService';
import { useAuth } from '../../AuthContext';

const DeckSelectionStep = ({ onSelect }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [decks, setDecks] = useState([]);
  const [adminDecks, setAdminDecks] = useState([]);

  const { user } = useAuth();
  const deckService = useDeckService();
  const adminDeckService = useAdminDeckService();

  useEffect(() => {
    fetchAdminDecks();
    if (user) {
      fetchDecks();
    }
  }, [user]);

  const fetchAdminDecks = async () => {
    try {
      const response = await adminDeckService.getAdminDecks();
      setAdminDecks(response.data.admin_decks);
    } catch (error) {
      console.error('Error fetching admin decks:', error);
      setError(error.response?.data?.message || error.message);
    }
  };

  const fetchDecks = async () => {
    try {
      const response = await deckService.getDecks();
      setDecks(response.data.decks);
    } catch (error) {
      console.error('Error fetching decks:', error);
      setError(error.response?.data?.message || error.message);
    }
  };

  // Updated to accept deckId and deckName
  const handleDeckSelect = (deckId, deckName) => {
    setLoading(true);
    try {
      onSelect(deckId, deckName);  // Call parent component's onSelect with deckId and deckName
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, padding: '20px', backgroundColor: '#121212', color: '#fff' }}>
      {!user && (
        <InfoHint 
            message="Hinweis: Sie sind nicht eingeloggt und können das Spiel nur mit vorgeschlagenen Decks testen."
        >
            <Link to="/LogIn" style={{ color: '#90CAF9', textDecoration: 'none' }}>
            Jetzt Anmelden
            </Link>
        </InfoHint>
      )}
      
      <DeckSelector
        decks={decks}
        adminDecks={adminDecks}
        onSelect={handleDeckSelect} // Pass handleDeckSelect as the callback
        user={user}
      />
      
      {loading && <Typography variant="body2" color="textSecondary">Laden...</Typography>}
      {error && <Typography variant="body2" color="error">{error}</Typography>}
    </Box>
  );
};

export default DeckSelectionStep;
