import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton, Box, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  boxShadow: 'none',
  border: 'none',
  borderBottom: '1px solid white',
}));

const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
});

const StyledIconWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginRight: '10px',
});

const CustomizableHeader = ({ leftIcon, leftText, rightContent }) => {

  const leftContent = (
    <Box display="flex" alignItems="center">
      <StyledIconWrapper>
        {leftIcon}
      </StyledIconWrapper>
      <Typography variant="body-large" component="div">
        {leftText}
      </Typography>
    </Box>
  );

  return (
    <StyledAppBar position="static">
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {leftContent}
        {rightContent}
      </Toolbar>
    </StyledAppBar>
  );
};

export default CustomizableHeader;