import React from 'react';
import { Box, Typography, Button, Stack, Divider, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import GitHubIcon from '@mui/icons-material/GitHub';
import CustomizableHeader from './common/CustomizeableHeader';
import { Info as InfoIcon } from '@mui/icons-material';

const About = () => {
  return (
    <>
      <CustomizableHeader
        leftIcon={<InfoIcon />}
        leftText="Über uns"
        rightContent={null}
      />
      <Box sx={{ flexGrow: 1, padding: 3, minHeight: '90vh', bgcolor: '#121212' }}>      
        <Box sx={{ 
          border: '1px solid rgba(255, 255, 255, 0.12)', 
          borderRadius: '4px', 
          padding: 2,
          bgcolor: '#1E1E1E',
          color: 'white'
        }}>
          <Typography variant="h5" >Was ist Facettenschach?</Typography>
          <Typography align="left">
            Facettenschach ist eine innovative Schachvariante, die durch den Einbau von Spezialzügen und Effekten eine neue Spielerfahrung bietet.
          </Typography>
          
          <Divider sx={{ my: 2, bgcolor: 'rgba(255, 255, 255, 0.12)' }} />
          
          <Typography variant="h5" >Über diese Plattform</Typography>
          <Typography align="left">
            Diese Online-Plattform wurde entwickelt, um persönliche Decks zu erstellen und zu verwalten. Anschließend kann damit gespielt werden.
          </Typography>
          
          <Divider sx={{ my: 2, bgcolor: 'rgba(255, 255, 255, 0.12)' }} />
          
          <Typography variant="h5" >Erfinder des Spiels</Typography>
          <Typography align="left">
            Idee und Konzeption von Felix Gappel.
          </Typography>
          
          <Divider sx={{ my: 2, bgcolor: 'rgba(255, 255, 255, 0.12)' }} />
          
          <Typography variant="h5"> Technische Entwicklung</Typography>
          <Box display="flex" justifyContent="flex-start" width="100%">
            <Stack direction="row" spacing={2}>
              <Link
                to="/external-link"
                state={{ url: 'https://github.com/py-bay' }}
                style={{ textDecoration: 'none' }}
              >
                <Button variant="outlined" startIcon={<GitHubIcon />} sx={{ color: 'white', borderColor: 'white' }}>
                  py-bay
                </Button>
              </Link>
              <Link
                to="/external-link"
                state={{ url: 'https://github.com/ellith' }}
                style={{ textDecoration: 'none' }}
              >
                <Button variant="outlined" startIcon={<GitHubIcon />} sx={{ color: 'white', borderColor: 'white' }}>
                  Ellith
                </Button>
              </Link>
            </Stack>
          </Box>
          
          <Divider sx={{ my: 2, bgcolor: 'rgba(255, 255, 255, 0.12)' }} />
          
          <Typography variant="h5" >Kontakt</Typography>
          <Typography align="left">
            Für Fragen, Anregungen oder Feedback kontaktieren Sie uns bitte unter:{' '}
            <MuiLink href="mailto:flashflexx965@gmail.com" sx={{ color: '#90CAF9' }}>chesscraft@web.de</MuiLink>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default About;