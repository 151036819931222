// components/cards/FullCardItem.js
import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography, Paper } from "@mui/material";

const CardContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: `${theme.palette.primary.main}22`,
  padding: theme.spacing(2),
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.divider}`,
  position: "relative",
  minHeight: "60px",
  margin: "8px",
  cursor: "pointer",
}));

const CardHeader = styled("div")({
  fontWeight: "bold",
  fontSize: "16px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const BlockerBadge = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "10%",
  right: "5%",
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  padding: "0.5em 0.75em",
  borderRadius: "4px",
  fontSize: "1em",
  fontWeight: "bold",
}));

const FullCardItem = ({ card, handleCardClick }) => {
  return (
    <CardContainer onClick={handleCardClick}>
      <CardHeader>
        {card.name}
        {card.blocker > 0 && <BlockerBadge>{card.blocker}</BlockerBadge>}
      </CardHeader>
      <Typography variant="subtitle2" color="textSecondary">
        {card.category}
      </Typography>
      <Box mt={2}>
        <Typography variant="body2">{card.effect}</Typography>
      </Box>
    </CardContainer>
  );
};


export default FullCardItem;
