import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, Divider, IconButton, Menu, MenuItem, Button, Stack } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LoginIcon from '@mui/icons-material/Login';
import { useAuth } from '../AuthContext';
import SuccessBanner from './SuccessBanner';
import { useNewsService } from './../services/newsService';
import NewsContent from './common/NewsContent';
import CustomizableHeader from './common/CustomizeableHeader';
import { styled } from '@mui/material/styles';

const Logo = styled('img')({
  width: '40px',
  height: '40px',
});

const Home = () => {
  const { user, isLoading, logout } = useAuth();
  const [showLoginSuccess, setShowLoginSuccess] = useState(false);
  const [news, setNews] = useState([]);
  const newsService = useNewsService();
  const navigate = useNavigate();
  
  const location = useLocation();
  const successMessage = location.state?.successMessage || '';

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
    navigate('/');
  };

  const hideLoginSuccess = useCallback(() => {
    setShowLoginSuccess(false);
    sessionStorage.removeItem('loginSuccess');
  }, []);

  useEffect(() => {
    const loginSuccess = sessionStorage.getItem('loginSuccess');
    if (loginSuccess === 'true') {
      setShowLoginSuccess(true);
      const timer = setTimeout(hideLoginSuccess, 5000);
      return () => clearTimeout(timer);
    }
  }, [hideLoginSuccess]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await newsService.getNews();
        setNews(response.data.news);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchNews();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const rightContent = user ? (
    <div>
      <IconButton color="inherit" onClick={handleMenu}>
        <AccountCircleIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>Abmelden</MenuItem>
      </Menu>
    </div>
  ) : (
    <Link to="/login" style={{ textDecoration: 'none', color: 'inherit' }}>
      <IconButton color="inherit">
        <LoginIcon />
      </IconButton>
    </Link>
  );

  return (
    <>
      <CustomizableHeader
        leftIcon={<Logo src="/logo192.png" alt="Logo" />}
        leftText="Facettenschach"
        rightContent={rightContent}
      />
      <Box sx={{ flexGrow: 1, padding: 3, minHeight: '90vh', bgcolor: '#121212' }}>
        {showLoginSuccess && <SuccessBanner message={successMessage || "Erfolgreich angemeldet!"} />}
        
        <Box sx={{
          border: '1px solid rgba(255, 255, 255, 0.12)',
          borderRadius: '4px',
          padding: 2,
          bgcolor: '#1E1E1E',
          color: 'white',
          marginBottom: 2
        }}>
          <Typography variant="h5">Willkommen 🎉</Typography>
          <Typography align="left">
            Entdecke eine neue Dimension des Schachs! Baue dein Kartendeck mit spannenden Effekten und revolutioniere dein Spiel. Neugierig? <Link to="/anleitung" style={{ color: '#90CAF9' }}>Zur Anleitung</Link>
          </Typography>
          
          {!user && (
            <>
              <Divider sx={{ my: 2, bgcolor: 'rgba(255, 255, 255, 0.12)' }} />
              <Typography variant="body2">
                <Link to="/LogIn" style={{ color: '#90CAF9' }}>Anmelden</Link> oder <Link to="/Register" style={{ color: '#90CAF9' }}>Registrieren</Link> 🔐
              </Typography>
            </>
          )}
        </Box>

        {news.length > 0 && (
          <Box sx={{
            border: '1px solid rgba(255, 255, 255, 0.12)',
            borderRadius: '4px',
            padding: 2,
            bgcolor: '#1E1E1E',
            color: 'white',
            marginBottom: 2
          }}>
            <Typography variant="h5">Neuigkeiten 📰</Typography> 
            <NewsContent news={news} showToggleButton={false} />
          </Box>
        )}
        
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <img src="/panda.png" alt="Panda" style={{ maxWidth: '300px', width: '100%', height: 'auto' }} />
        </Box>
      </Box>
    </>
  );
};

export default Home;