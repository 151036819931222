import { useApiClient } from './apiClient';

export const useCardService = () => {
  const apiClient = useApiClient();
  
  return {
    getCards: () => apiClient.get('api/cards'),
    createCard: (newCard) => apiClient.post('api/cards', JSON.stringify(newCard)),
    updateCard: (id, cardData) => {
      return apiClient.put(`api/cards/${id}`, JSON.stringify(cardData));
    },
    deleteCard: (CardId) => apiClient.delete(`api/cards/${CardId}`)
};
};