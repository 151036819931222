import React from 'react';
import { Box, Typography, Divider, Link as MuiLink } from '@mui/material';
import CustomizableHeader from './common/CustomizeableHeader';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';

const PrivacyPolicy = () => {
  return (
    <>
      <CustomizableHeader
        leftIcon={<PrivacyTipIcon />}
        leftText="Datenschutzerklärung"
        rightContent={null}
      />
      <Box sx={{ flexGrow: 1, padding: 3, minHeight: '90vh', bgcolor: '#121212' }}>
        <Box sx={{
          border: '1px solid rgba(255, 255, 255, 0.12)',
          borderRadius: '4px',
          padding: 2,
          bgcolor: '#1E1E1E',
          color: 'white',
        }}>
          <Typography variant="h5">Datenschutzerklärung</Typography>
          <Typography align="left" sx={{ mt: 2 }}>
            Die Datenschutzerklärung wird in Kürze ergänzt. Bitte schauen Sie bald wieder vorbei.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
