import React from 'react';
import { Box } from '@mui/material';
import GameStepper from './playing/GameStepper';
import CustomizableHeader from './common/CustomizeableHeader';
import { Games as GameIcon } from '@mui/icons-material';

const PlayingComponent = () => {
  return (
    <>
      <CustomizableHeader leftIcon={<GameIcon />} leftText="Spielen" />
      <Box sx={{ flexGrow: 1, padding: 3, minHeight: '90vh' }}>
        <GameStepper />
      </Box>
    </>
  );
};

export default PlayingComponent;
