import { useApiClient } from './apiClient';

export const useAdminDeckService = () => {
  const apiClient = useApiClient();

  return {
    createAdminDeck: (name, description, cardIds) =>
      apiClient.post('api/admin-decks', { name, description, card_ids: cardIds }),
   
    getAdminDecks: () =>
      apiClient.get('api/admin-decks'),

    getAdminDeck: (deckId) =>
      apiClient.get(`api/admin-decks/${deckId}`),
   
    updateAdminDeck: (deckId, name, description, cardIds) =>
      apiClient.put(`api/admin-decks/${deckId}`, { name, description, card_ids: cardIds }),

    deleteAdminDeck: (deckId) =>
      apiClient.delete(`api/admin-decks/${deckId}`),
  };
};