// components/cards/AvailableCardItem.js
import React, { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Typography, Box, Modal, Backdrop } from '@mui/material';

const CardContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.divider}`,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  transition: 'background-color 0.3s ease',
  position: 'relative',
  minHeight: '60px',
  margin: '8px',
}));

const CardHeader = styled('div')({
  fontWeight: 'bold',
  fontSize: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const CardCategory = styled('div')({
  fontSize: '14px',
  color: 'gray',
});

const BlockerBadge = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '15%',
  right: '5%',
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  padding: '0.5em 0.75em',
  borderRadius: '4px',
  fontSize: '1em', // Scales relative to modal content size
  fontWeight: 'bold',
  transform: 'scale(1)',
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  borderRadius: '8px',
  boxShadow: theme.shadows[5],
  width: '300px', // Adjust width as needed
  outline: 'none',
  position: 'relative', // Allows positioning for the BlockerBadge
}));

const AvailableCardItem = ({ card }) => {
  const [open, setOpen] = useState(false);

  const handleCardClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid xs={6} sm={4} md={3}>
      <CardContainer onClick={handleCardClick}>
        <CardHeader>
          {card.name}
          {card.blocker > 0 && (
            <BlockerBadge>{card.blocker}</BlockerBadge>
          )}
        </CardHeader>
        <CardCategory>{card.category}</CardCategory>
      </CardContainer>

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: {
            backdropFilter: 'blur(5px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <ModalContent>
          <Typography variant="h5">{card.name}</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {card.category}
          </Typography>
          {card.blocker > 0 && (
            <BlockerBadge style={{ position: 'absolute', top: 8, right: 8 }}>
              {card.blocker}
            </BlockerBadge>
          )}
          <Box mt={2}>
            <Typography variant='body'>{card.effect}</Typography>
          </Box>
        </ModalContent>
      </Modal>
    </Grid>
  );
};

export default AvailableCardItem;
