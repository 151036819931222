import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useAdminDeckService } from "../../services/adminDeckService";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import ActionButton from '../common/ActionButton';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeckIcon from '@mui/icons-material/ViewModule';
import AdminIcon from '@mui/icons-material/AdminPanelSettings';
import CustomizableHeader from "../common/CustomizeableHeader";

const DarkPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: '#1E1E1E',
  color: 'white',
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
}));

const DeckItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
  },
}));

const AdminDeckManager = () => {
  const [decks, setDecks] = useState([]);
  const [loading, setLoading] = useState(true);
  const adminDeckService = useAdminDeckService();
  const navigate = useNavigate();

  useEffect(() => {
    fetchDecks();
  }, []);

  const fetchDecks = async () => {
    try {
      const response = await adminDeckService.getAdminDecks();
      setDecks(response.data.admin_decks);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching decks:', error);
      setLoading(false);
    }
  };

  const handleEdit = (deck) => {
    navigate('/admin/decks/create', { state: { editDeck: deck } });
  };

  const handleDelete = async (deckId) => {
    if (window.confirm('Are you sure you want to delete this deck?')) {
      try {
        await adminDeckService.deleteAdminDeck(deckId);
        fetchDecks();
      } catch (error) {
        console.error('Error deleting deck:', error);
      }
    }
  };

  const handleCreateDeckClick = () => {
    navigate('/admin/decks/create');
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizableHeader
          leftIcon={<DeckIcon />}
          leftText="Admin-Decks"
          rightContent={
            <Link to="/admin" style={{ color: 'inherit' }}>
              <IconButton sx={{ color: 'white' }}>
                <AdminIcon />
              </IconButton>
            </Link>
          }
        />
        <Box sx={{
          border: '1px solid rgba(255, 255, 255, 0.12)',
          borderRadius: '4px',
          padding: 2,
          bgcolor: '#1E1E1E',
          color: 'white',
          margin: 2,
        }}>
        

        <Box sx={{ marginBottom: 3 }}>
          <Button
            startIcon={<AddIcon />}
            onClick={handleCreateDeckClick}
            variant="contained"
            color="primary"
          >
            Neues Deck erstellen
          </Button>
        </Box>
        
        <Divider sx={{ my: 2, backgroundColor: 'rgba(255, 255, 255, 0.12)' }} />
        
        {decks.map((deck, index) => (
          <React.Fragment key={deck.id}>
            <DeckItem>
              <Grid container spacing={2} alignItems="center">
                <Grid xs={12} sm={6}>
                  <Typography variant="h5">{deck.name}</Typography>
                  <Typography align="left">{deck.description}</Typography>
                </Grid>
                <Grid xs={12} sm={6} container justifyContent="flex-end" spacing={1}>
                  <Grid>
                    <ActionButton action="edit" onClick={() => handleEdit(deck)} />
                  </Grid>
                  <Grid>
                    <ActionButton action="delete" onClick={() => handleDelete(deck.id)} color="error" />
                  </Grid>
                </Grid>
              </Grid>
            </DeckItem>
            
            {index < decks.length - 1 && (
              <Divider sx={{ my: 2, backgroundColor: 'rgba(255, 255, 255, 0.12)' }} />
            )}
          </React.Fragment>
        ))}
      </Box>
    </>
  );
};

export default AdminDeckManager;
