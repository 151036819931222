import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Box, Typography, IconButton, Grid, Switch, CircularProgress } from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PeopleIcon from '@mui/icons-material/People';
import { useUserService } from "../../services/userService";
import CustomizableHeader from '../common/CustomizeableHeader';

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [messages, setMessages] = useState({});
  const [loading, setLoading] = useState({});
  const userService = useUserService();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await userService.getUsers();
      setUsers(response.data.users);
    } catch (error) {
      setMessages((prev) => ({ ...prev, global: 'Error fetching users: ' + (error.response?.data?.message || error.message) }));
    }
  };

  const handleToggleAdmin = async (id) => {
    setLoading((prev) => ({ ...prev, [id]: true }));
    try {
      await userService.toggleAdminStatus(id);
      setMessages((prev) => ({ ...prev, [id]: 'Admin status toggled successfully' }));
      fetchUsers();
    } catch (error) {
      setMessages((prev) => ({ ...prev, [id]: 'Error toggling admin status: ' + (error.response?.data?.message || error.message) }));
    } finally {
      setLoading((prev) => ({ ...prev, [id]: false }));
      setTimeout(() => {
        setMessages((prev) => {
          const updatedMessages = { ...prev };
          delete updatedMessages[id];
          return updatedMessages;
        });
      }, 3000);
    }
  };

  return (
    <Box sx={{ backgroundColor: '#121212', minHeight: '100vh' }}>
      <CustomizableHeader
        leftIcon={<PeopleIcon sx={{ color: '#FFF' }} />}
        leftText="Verwaltung"
        rightContent={
          <Link to="/admin">
            <IconButton sx={{ color: '#FFF' }}>
              <AdminPanelSettingsIcon />
            </IconButton>
          </Link>
        }
      />
      <Box sx={{ flexGrow: 1, padding: 3, minHeight: '90vh' }}>
        <Box sx={{
          border: '1px solid rgba(255, 255, 255, 0.12)',
          borderRadius: '4px',
          padding: 2,
          bgcolor: '#1E1E1E',
          color: 'white',
          marginBottom: 2
        }}>
          <Typography variant="h5">Benutzer verwalten</Typography>
          
          {messages.global && (
            <Box sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              borderRadius: '4px',
              padding: 2,
              marginBottom: 2
            }}>
              <Typography>{messages.global}</Typography>
            </Box>
          )}

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {users.map(user => (
              <Box key={user.id} sx={{
                padding: 2,
                marginBottom: 2,
                border: '1px solid rgba(255, 255, 255, 0.12)',
                borderRadius: '4px',
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
              }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  {user.username}
                </Typography>
                
                <Grid container alignItems="center" spacing={1} sx={{ mt: 1 }}>
                  <Grid item>
                    <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                      Admin-Rechte:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={user.is_admin}
                      onChange={() => handleToggleAdmin(user.id)}
                      color="primary"
                      inputProps={{ 'aria-label': `toggle admin status for ${user.username}` }}
                    />
                    {loading[user.id] && <CircularProgress size={24} sx={{ marginLeft: 1 }} />}
                  </Grid>
                </Grid>
                
                {messages[user.id] && (
                  <Box sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    borderRadius: '4px',
                    padding: 1,
                    marginTop: 1
                  }}>
                    <Typography>{messages[user.id]}</Typography>
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AdminUsers;
