import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import IconButton from '@mui/material/IconButton';
import NewsIcon from '@mui/icons-material/Announcement';
import { Link } from 'react-router-dom';
import { useNewsService } from "./../../services/newsService";
import CustomStyledTextField from '../common/CustomStyledTextField';
import ActionButton from '../common/ActionButton';
import NewsContent from '../common/NewsContent';
import CustomizableHeader from '../common/CustomizeableHeader';
import Typography from '@mui/material/Typography';
import { Divider } from "@mui/material";

const AdminNews = () => {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [news, setNews] = useState([]);

    const newsService = useNewsService();

    useEffect(() => {
        fetchNews();
    }, []);

    const fetchNews = async () => {
        try {
            const response = await newsService.getNews();
            setNews(response.data.news);
        } catch (error) {
            // TODO: add error alert
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await newsService.createNews({ title, content });
            setTitle("");
            setContent("");
            fetchNews();
        } catch (error) {
            // TODO: add error alert
        }
    };


    const handleDelete = async (id) => {
        try {
          await newsService.deleteNews(id);
          fetchNews();
        } catch (error) {
          // TODO: add error alert
        }
      };

    return (
        <>
            <CustomizableHeader
                leftIcon={<NewsIcon />}
                leftText="News"
                rightContent={
                    <Link to="/admin">
                        <IconButton>
                            <AdminPanelSettingsIcon />
                        </IconButton>
                    </Link>
                }
            />
            <Box sx={{ flexGrow: 1, padding: 3, minHeight: '90vh', bgcolor: '#121212' }}>
                <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                    <Box sx={{
                        border: '1px solid rgba(255, 255, 255, 0.12)',
                        borderRadius: '4px',
                        padding: 2,
                        bgcolor: '#1E1E1E',
                        color: 'white',
                        marginBottom: 2
                        }}>
                            <Typography variant="h5">Neue News 📰</Typography>
                            <form onSubmit={handleSubmit} style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '16px',
                                alignItems: 'center'
                            }}>
                                <CustomStyledTextField
                                    label="Titel"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    required
                                    fullWidth
                                />
                                <CustomStyledTextField
                                    label="Inhalt"
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                    required
                                    fullWidth
                                    multiline
                                    rows={2}
                                />
                                <ActionButton
                                    action="save"
                                    onClick={handleSubmit}
                                    color="primary"
                                >
                                    Veröffentlichen
                                </ActionButton>
                            </form>
                        
                            <Divider sx={{ my: 2, bgcolor: 'rgba(255, 255, 255, 0.12)' }} />

                            <Typography variant="h5">News verwalten ✏️</Typography>
                            <NewsContent 
                                news={news} 
                                showDeleteButton={true}
                                onDelete={handleDelete}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default AdminNews;
