import React from 'react';
import { Box, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const InfoHint = ({ message, children, variant = "body2" }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', padding: 2, backgroundColor: '#212121', borderRadius: 2, mb: 2 }}>
      <InfoIcon sx={{ color: '#1976d2', mr: 1 }} />
      <Typography variant={variant} color="textSecondary" component="span">
        {message}&nbsp;
        {children}
      </Typography>
    </Box>
  );
};

export default InfoHint;