import { useApiClient } from './apiClient';

export const useUserService = () => {
    const apiClient = useApiClient();
  
    return {
      getUsers: () => apiClient.get('api/users'),
      deleteUser: (id) => apiClient.delete(`api/users/${id}`),
      setUserPassword: (id, newPassword) => apiClient.put(`api/users/${id}/set-password`, { password: newPassword }),
      toggleAdminStatus: (id) => apiClient.put(`api/users/${id}/toggle-admin`),
    };
  };