import React from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import { Info as InfoIcon } from '@mui/icons-material';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import InfoIconOutlined from '@mui/icons-material/InfoOutlined';
import CustomizableHeader from './common/CustomizeableHeader';

const Settings = () => {
  return (
    <>
      <CustomizableHeader
        leftIcon={<SettingsIcon />}
        leftText="Einstellungen"
        rightContent={null}
      />
      <Box sx={{ flexGrow: 1, padding: 3, minHeight: '90vh', bgcolor: '#121212' }}>
        <Box sx={{
          border: '1px solid rgba(255, 255, 255, 0.12)',
          borderRadius: '4px',
          padding: 2,
          bgcolor: '#1E1E1E',
          color: 'white',
        }}>
          <Typography variant="h5">Einstellungen</Typography>
          <Typography align="left">
            Hier lässt sich bald etwas einstellen.
          </Typography>

          <Divider sx={{ my: 2, bgcolor: 'rgba(255, 255, 255, 0.12)' }} />
        
          <Typography variant="h5">Links</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Link to="/about" style={{ textDecoration: 'none' }}>
              <Button
                variant="outlined"
                startIcon={<InfoIcon />}
                sx={{ color: 'white', borderColor: 'white' }}
              >
                Über uns
              </Button>
            </Link>
            <Link to="/privacy-policy" style={{ textDecoration: 'none' }}>
              <Button
                variant="outlined"
                startIcon={<PrivacyTipIcon />}
                sx={{ color: 'white', borderColor: 'white' }}
              >
                Datenschutzerklärung
              </Button>
            </Link>
            <Link to="/imprint" style={{ textDecoration: 'none' }}>
              <Button
                variant="outlined"
                startIcon={<InfoIconOutlined />}
                sx={{ color: 'white', borderColor: 'white' }}
              >
                Impressum
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Settings;
