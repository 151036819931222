import { createTheme } from '@mui/material/styles';

const customTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90CAF9',
    },
    secondary: {
      main: '#6c757d',
    },
    background: {
      default: '#121212',
      paper: '#212121',
    },
    text: {
      primary: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'monospace, serif',
    fontSize: 14,
    h5: {
      color: '#90CAF9',
      textAlign: "left",
      marginBottom: '0.3em',
    },
    h6: {
      color: '#ffffff',
      textAlign: "left",
      marginBottom: '0.3em',
    },
    body: {
      color: '#ffffff',
    },
    textSizes: {
      small: '0.875rem',    // 14px
      normal: '1rem',       // 16px
      large: '1.25rem',     // 20px
      extraLarge: '1.5rem', // 24px
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          border: '1px solid white',
          backgroundColor: '#121212',
          color: 'white',
          '&:hover': {
            backgroundColor: '#4a4a4a',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#212121',
          color: 'white',
          border: '1px solid white',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'white',
          textDecoration: 'underline',
          '&:hover': {
            color: 'rgba(255, 255, 255, 0.7)',
          },
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'body-small' },
          style: {
            fontSize: '0.875rem', // Same as textSizes.small
          },
        },
        {
          props: { variant: 'body-normal' },
          style: {
            fontSize: '1rem', // Same as textSizes.normal
          },
        },
        {
          props: { variant: 'body-large' },
          style: {
            fontSize: '1.25rem', // Same as textSizes.large
          },
        },
        {
          props: { variant: 'body-xlarge' },
          style: {
            fontSize: '1.5rem', // Same as textSizes.extraLarge
          },
        },
      ],
    },
  },
});

export default customTheme;