import React from 'react';
import { Typography, Box, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import ActionButton from './ActionButton';

const NewsItem = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  borderRadius: theme.shape.borderRadius,
  '&:last-child': {
    marginBottom: 0,
  },
}));

const NewsDate = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.8rem',
  marginTop: theme.spacing(1),
}));

const NewsContent = ({ news, showDeleteButton = false, onDelete  }) => {
  return (
    <>
      {news.map((item, index) => (
        <React.Fragment key={item.id}>
          {index > 0 && <Divider sx={{ my: 2, bgcolor: 'rgba(255, 255, 255, 0.12)' }} />}
          <NewsItem>
            <Typography variant="h6" gutterBottom>
              {item.title}
            </Typography>
            <Typography paragraph align='left'>
              {item.content}
            </Typography>
            <NewsDate variant="caption">
              Veröffentlicht am: {new Date(item.created_at).toLocaleDateString()}
            </NewsDate>
            {showDeleteButton && (
              <ActionButton
                action="delete"
                onClick={() => onDelete(item.id)}
              />
            )}
          </NewsItem>
        </React.Fragment>
      ))}
    </>
  );
};

export default NewsContent;
