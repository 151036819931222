import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Typography, TextField, Button, Divider } from '@mui/material';
import { useAuthService } from '../services/authService';

const Register = () => {
  const authService = useAuthService();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleRegister = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage('');
    try {
      await authService.register(username, password);
      setSuccessMessage('Registrierung erfolgreich! Weiter zum LogIn...');
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response.data.message || 'Registrierung fehlgeschlagen');
      } else if (error.request) {
        setErrorMessage('Keine Antwort vom Server. Bitte überprüfen Sie Ihre Internetverbindung.');
      } else {
        setErrorMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 3, minHeight: '90vh', bgcolor: 'background.default' }}>
      <Typography variant="body-norrmal" sx={{ color: 'text.primary', mb: 2 }}>
        Facettenschach
      </Typography>
      <Box sx={{
        border: 1,
        borderColor: 'divider',
        borderRadius: 1,
        padding: 4,
        bgcolor: 'background.paper',
        color: 'text.primary',
        marginTop: 2
      }}>
        <Typography variant="h5" component="h1" gutterBottom align="left" color="primary">
          Registrieren
        </Typography>
        <form onSubmit={handleRegister}>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            required
            id="username"
            label="Benutzername"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
              setErrorMessage('');
            }}
            InputLabelProps={{
              required: false,
            }}
          />
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            required
            name="password"
            label="Passwort"
            type="password"
            id="password"
            autoComplete="new-password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setErrorMessage('');
            }}
            InputLabelProps={{
              required: false,
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              mt: 3,
              mb: 2,
              '&:hover': {
                backgroundColor: 'primary.dark',
              },
            }}
            disabled={isLoading}
          >
            {isLoading ? 'Registrierung...' : 'Registrieren'}
          </Button>
        </form>
        {errorMessage && (
          <Typography color="error" align="center" sx={{ mt: 2 }}>
            {errorMessage}
          </Typography>
        )}
        {successMessage && (
          <Typography color="success" align="center" sx={{ mt: 2 }}>
            {successMessage}
          </Typography>
        )}
        <Divider sx={{ my: 2 }} />
        <Typography variant="body2" align="center" sx={{ mt: 2 }}>
          Bereits registriert? <Link to="/login" style={{ color: '#90CAF9' }}>Anmelden</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Register;