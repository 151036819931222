import React from "react";
import HistoryIcon from '@mui/icons-material/History';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import IconButton from '@mui/material/IconButton';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import CustomizableHeader from "../common/CustomizeableHeader";

const AdminGameHistory = () => {
    return (
        <>
          <CustomizableHeader
            leftIcon={<HistoryIcon />}
            leftText="Spielhistorie"
            rightContent={
                <Link to="/admin">
                    <IconButton>
                        <AdminPanelSettingsIcon />
                    </IconButton>
                </Link>
            }
          />
          <Box sx={{ flexGrow: 1, padding: 3, minHeight: '90vh', bgcolor: '#121212' }}>
            <Box sx={{
              border: '1px solid rgba(255, 255, 255, 0.12)',
              borderRadius: '4px',
              padding: 2,
              bgcolor: '#1E1E1E',
              color: 'white',
            }}>
              <Typography sx={{ mt: 2 }}>
                Hier sollen in Zukunft die gespielten Spiele einsehbar sein.
              </Typography>
            </Box>
          </Box>
        </>
      );
    };

export default AdminGameHistory;