import React from 'react';
import { Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme, clickable }) => ({
  backgroundColor: '#212121',
  padding: theme.spacing(2),
  textAlign: 'center',
  color: 'white',
  border: '1px solid white',
  fontFamily: 'monospace, serif',
  fontSize: '16px',
  cursor: clickable ? 'pointer' : 'default',
  ...theme.typography.body2,
}));

const Item = ({ title, children, clickable }) => {
  return (
    <StyledPaper clickable={clickable}>
      {title && (
        <Typography variant="h5" component="h2" gutterBottom>
          {title}
        </Typography>
      )}
      {children}
    </StyledPaper>
  );
};

export default Item;
