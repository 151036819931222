import React from "react";
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import CustomizableHeader from '../common/CustomizeableHeader';
import Box from '@mui/material/Box';
import { AdminPanelSettings as AdminIcon} from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import PeopleIcon from '@mui/icons-material/People';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DeckIcon from '@mui/icons-material/ViewModule';
import HistoryIcon from '@mui/icons-material/History';
import NewsIcon from '@mui/icons-material/Announcement';
import Item from '../common/Item';

const StyledLink = styled(Link)({
  color: '#fff',
  textDecoration: 'none',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    textDecoration: 'underline',
  },
  '&:focus': {
    outline: 'none',
  },
});

const AdminPortal = () => {
  return (
    <Box sx={{ flexGrow: 1, backgroundColor: 'background.default', color: 'text.primary', minHeight: '100vh' }}>
    <CustomizableHeader leftIcon={<AdminIcon />} leftText="Admin Portal"/>
      <Box sx={{ flexGrow: 1, backgroundColor: '#121212', color: '#fff', padding: '20px', minHeight: '80vh' }}>
        <Grid item xs={12}>
          <Item>
            <div style={{ fontSize: '1.125em', letterSpacing: '0.05em', lineHeight: '1.125', textAlign: 'left' }}>
              <StyledLink to="/admin/users">
                <PeopleIcon sx={{ marginRight: '12px', marginLeft: '8px' }} /> Benutzer
              </StyledLink><br />
              <StyledLink to="/admin/cards">
                <CreditCardIcon sx={{ marginRight: '12px', marginLeft: '8px' }} /> Karten
              </StyledLink><br />
              <StyledLink to="/admin/decks">
                <DeckIcon sx={{ marginRight: '12px', marginLeft: '8px' }} /> Admin-Decks
              </StyledLink><br />
              <StyledLink to="/admin/gamehistory">
                <HistoryIcon sx={{ marginRight: '12px', marginLeft: '8px' }} /> Spielhistorie
              </StyledLink><br />
              <StyledLink to="/admin/news">
                <NewsIcon sx={{ marginRight: '12px', marginLeft: '8px' }} /> News
              </StyledLink>
            </div>
          </Item>
        </Grid>
      </Box>
    </Box>
  );
};

export default AdminPortal;
