import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { Science as TestIcon } from '@mui/icons-material';
import CustomizableHeader from "./common/CustomizeableHeader";
import FullCardItem from './cards/FullCardItem';
import ActionButton from "./common/ActionButton";
import { useDeckService } from '../services/deckService';
import { useAdminDeckService } from '../services/adminDeckService';
import AvailableCardsModal from "./playing/AvailableCardsModal";
import { Grid, Box, Modal, Backdrop } from '@mui/material';

const Testen = () => {
  const navigate = useNavigate(); 
  const { deckId: routeDeckId } = useParams();
  const deckId = routeDeckId;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [displayedCards, setDisplayedCards] = useState([]);
  const [availableCards, setAvailableCards] = useState([]);
  const [remainingCards, setRemainingCards] = useState(0);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isModalLocked, setIsModalLocked] = useState(false);
  const [isCardModalOpen, setIsCardModalOpen] = useState(false);
  const [isAvailableCardsModalOpen, setIsAvailableCardsModalOpen] = useState(false);
  const [jokerCount, setJokerCount] = useState(3);

  const deckService = useDeckService();
  const adminDeckService = useAdminDeckService();

  useEffect(() => {
    if (deckId) {
      loadDeck(deckId);
    }
  }, [deckId]);

  const loadDeck = async (deckId) => {
    setLoading(true);
    setError(null);

    try {
      const response = await deckService.getDeck(deckId);
      initializeDeck(response.data.deck.cards);
    } catch (userDeckError) {
      if (userDeckError.response) {
        try {
          const adminResponse = await adminDeckService.getAdminDeck(deckId);
          initializeDeck(adminResponse.data.admin_deck.cards);
        } catch (adminDeckError) {
          setError("Deck konnte nicht geladen werden.");
        }
      } else {
        setError(userDeckError.response?.data?.message || userDeckError.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const initializeDeck = (deckCards) => {
    const shuffledCards = deckCards.sort(() => 0.5 - Math.random());
    setDisplayedCards(shuffledCards.slice(0, 3));
    setAvailableCards(shuffledCards.slice(3));
    setRemainingCards(shuffledCards.length - 3);
  };

  const handleEndTesten = () => {
    navigate('/spielen');
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
    setIsModalLocked(false);
    setIsCardModalOpen(true);
  };

  const handleUseCard = () => {
    setIsModalLocked(true);
  };

  const handleActionClick = () => {
    if (selectedCard && selectedCard.blocker > 1) {
      selectedCard.blocker -= 1;
      setSelectedCard({ ...selectedCard }); // Update selectedCard state to reflect blocker reduction
    } else {
      setDisplayedCards((prevCards) =>
        prevCards.filter((c) => c.id !== selectedCard.id)
      );

      if (availableCards.length > 0) {
        const newCard = availableCards[0];
        setDisplayedCards((prevCards) => [...prevCards, newCard]);
        setAvailableCards((prevCards) => prevCards.slice(1));
        setRemainingCards((prev) => prev - 1);
      }

      setIsCardModalOpen(false);
      setSelectedCard(null);
    }
  };

  const handleCancelClick = () => {
    setIsCardModalOpen(false);
    setSelectedCard(null);
  };

  const handleJokerEinsatz = () => {
    if (jokerCount <= 0) {
      console.warn("No jokers left.");
      return;
    }

    if (availableCards.length < 3) {
      console.warn("Not enough cards available to replace displayed cards.");
      return;
    }

    const newDisplayedCards = [];
    const updatedAvailableCards = [...availableCards];

    for (let i = 0; i < 3; i++) {
      const randomIndex = Math.floor(Math.random() * updatedAvailableCards.length);
      newDisplayedCards.push(updatedAvailableCards[randomIndex]);
      updatedAvailableCards.splice(randomIndex, 1);
    }

    setDisplayedCards(newDisplayedCards);
    setAvailableCards(updatedAvailableCards);
    setRemainingCards(updatedAvailableCards.length);
    setJokerCount(jokerCount - 1);
  };

  return (
    <>
      <CustomizableHeader
        leftIcon={<TestIcon />}
        leftText="Testen"
        rightContent={<ActionButton action="cancel" onClick={handleEndTesten}>Testen beenden</ActionButton>}
      />
      <Box sx={{ flexGrow: 1, padding: '20px', backgroundColor: '#121212', color: '#fff' }}>
        <Grid container direction="column" spacing={2}>
          {displayedCards.map((card) => (
            <Grid item xs={12} key={card.id}>
              <FullCardItem
                card={card}
                handleCardClick={() => handleCardClick(card)}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <ActionButton onClick={handleJokerEinsatz} disabled={jokerCount <= 0} fullWidth>
              Joker einsetzen ({jokerCount} übrig)
            </ActionButton>
          </Grid>
          <Grid item xs={12}>
            <ActionButton onClick={() => setIsAvailableCardsModalOpen(true)} fullWidth>
              Verfügbare Karten: {remainingCards}
            </ActionButton>
          </Grid>
        </Grid>
      </Box>

      <Modal
        open={isCardModalOpen}
        onClose={!isModalLocked ? handleCancelClick : undefined}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: {
            backdropFilter: 'blur(5px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80vw',
            maxWidth: '400px',
            backgroundColor: 'rgba(18, 18, 18, 0.9)',
            color: 'white',
            borderRadius: 2,
            padding: 3,
            boxShadow: 24,
            border: `1px solid rgba(255, 255, 255, 0.2)`,
          }}
        >
          {selectedCard && (
            <>
              <FullCardItem card={selectedCard} />
              <Box mt={2} display="flex" justifyContent="space-around">
                {!isModalLocked ? (
                  <>
                    <ActionButton onClick={handleCancelClick}>Abbrechen</ActionButton>
                    <ActionButton onClick={handleUseCard}>Karte einsetzen</ActionButton>
                  </>
                ) : (
                  <ActionButton onClick={handleActionClick}>Zug machen</ActionButton>
                )}
              </Box>
            </>
          )}
        </Box>
      </Modal>

      <AvailableCardsModal
        open={isAvailableCardsModalOpen}
        onClose={() => setIsAvailableCardsModalOpen(false)}
        availableCards={availableCards}
      />
    </>
  );
};

export default Testen;
