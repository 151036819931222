import { useApiClient } from './apiClient';

export const useDeckService = () => {
  const apiClient = useApiClient();
   
  return {
    getDecks: () => apiClient.get('/api/decks'),
    getDeck: (deckId) => apiClient.get(`/api/deck/${deckId}`),
    createDeck: (cardIds, name) => apiClient.post('/api/deck', { card_ids: cardIds, name: name }),
    updateDeck: (deckId, cardIds, name) => apiClient.put(`/api/deck/${deckId}`, { card_ids: cardIds, name: name }),
    deleteDeck: (deckId) => apiClient.delete(`/api/deck/${deckId}`),
  };
};