import React from 'react';
import { Box, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CustomizableHeader from './common/CustomizeableHeader';

const Imprint = () => {
  return (
    <>
      <CustomizableHeader
        leftIcon={<InfoIcon />}
        leftText="Impressum"
        rightContent={null}
      />
      <Box sx={{ flexGrow: 1, padding: 3, minHeight: '90vh', bgcolor: '#121212' }}>
        <Box sx={{
          border: '1px solid rgba(255, 255, 255, 0.12)',
          borderRadius: '4px',
          padding: 2,
          bgcolor: '#1E1E1E',
          color: 'white',
        }}>
          <Typography variant="h5">Impressum</Typography>
          <Typography align="left" sx={{ mt: 2 }}>
            Das Impressum wird in Kürze ergänzt. Bitte schauen Sie bald wieder vorbei.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Imprint;
