import { Routes, Route, Navigate } from 'react-router-dom';

// public routes
import About from './components/About';
import Home from './components/Home';
import Register from './components/Register';
import LogIn from './components/LogIn';
import Anleitung from './components/Instruction';
import Menu from './components/Menu';
import Settings from './components/Settings';
import PrivacyPolicy from './components/PrivacyPolicy';
import Imprint from './components/Imprint';
import Testen from './components/Testen';

// private routes
import PrivateRoute from './PrivateRoute';
import Spielen from './components/Playing';
import Deckbuilding from './components/Deckbuilding';
import Decks from './components/Decks';

// admin routes
import AdminRoute from './AdminRoute';
import AdminPortal from './components/admin/AdminPortal';
import AdminUsers from './components/admin/AdminUsers';
import AdminCards from './components/admin/AdminCards';
import AdminDecksManager from './components/admin/AdminDecksManager';
import AdminDecksCreate from './components/admin/AdminDecksCreate';
import AdminGameHistory from './components/admin/AdminGameHistory';
import AdminNews from './components/admin/AdminNews';
import { ThemeProvider } from '@mui/material/styles';
import customTheme from './components/styles/customTheme';

// external links
import ExternalLink from './services/ExternalLink';

import { AuthProvider } from './AuthContext';

import './App.css';


function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <AuthProvider>
        <div className="App">
          <Menu />
          <Routes>
            <Route path="/about" element={<About/>} />
            <Route path="/login" element={<LogIn/>} />
            <Route path="/register" element={<Register/>} />
            <Route path="/home" element={<Home/>} />
            <Route path="/testen/:deckId" element={<Testen />} />
            <Route path="/anleitung" element={<Anleitung />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/imprint" element={<Imprint />} />
            <Route path="/spielen" element={<Spielen/>} />

            <Route path="/deckbuilding" element={<PrivateRoute><Deckbuilding/></PrivateRoute>} />
            <Route path="/decks" element={<PrivateRoute><Decks/></PrivateRoute>} />
            
            <Route path="/admin" element={<AdminRoute><AdminPortal/></AdminRoute>} />
            <Route path="/admin/users" element={<AdminRoute><AdminUsers/></AdminRoute>} />
            <Route path="/admin/cards" element={<AdminRoute><AdminCards/></AdminRoute>} />
            <Route path="/admin/decks" element={<AdminRoute><AdminDecksManager/></AdminRoute>} />
            <Route path="/admin/decks/create" element={<AdminRoute><AdminDecksCreate/></AdminRoute>} />
            <Route path="/admin/news" element={<AdminRoute><AdminNews/></AdminRoute>} />
            <Route path="/admin/gamehistory" element={<AdminRoute><AdminGameHistory/></AdminRoute>} />
            
            <Route path="/external-link" element={<ExternalLink />} />
            
            <Route path="*" element={<Navigate to="/home" replace />} />
          </Routes>
        </div>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;