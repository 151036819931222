import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, Paper, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { Home as HomeIcon, RecentActors as DecksIcon, Games as SpielenIcon, MoreHoriz as MoreIcon, Science as TestIcon, AdminPanelSettings as AdminIcon, Settings as SettingsIcon, Info as InfoIcon } from '@mui/icons-material';
import ArticleSharpIcon from '@mui/icons-material/ArticleSharp';
import { useAuth } from '../AuthContext';
import { styled } from '@mui/material/styles';

const StyledBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  justifyContent: 'center',
  '& .MuiBottomNavigationAction-root': {
    color: theme.palette.text.secondary,
    '&.Mui-selected': {
      color: theme.palette.primary.main,
    },
  },
}));

const BottomMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const path = location.pathname;
    if (path.includes('/home')) setValue(0);
    else if (path.includes('/decks')) setValue(1);
    else if (path.includes('/spielen') || path.includes('/testen')) setValue(2);
    else if (path.includes('/anleitung') || path.includes('/about') || path.includes('/admin') || path.includes('/settings')) setValue(3);
    else setValue(-1);
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 3) {
      handleMoreClick(event);
    }
  };

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
    handleClose();
  };

  return (
    <Paper 
      sx={{ 
        position: 'fixed', 
        bottom: 0, 
        left: 0, 
        right: 0,
        border: 'none', 
        overflow: 'hidden',
        borderRadius: '0%',
        borderTop: '1px solid white',
        zIndex: 999
      }} 
      elevation={3}
    >
      <StyledBottomNavigation
        value={value}
        onChange={handleChange}
        showLabels
      >
        <BottomNavigationAction
          label="Home"
          icon={
            <div style={{ position: 'relative' }}>
              <HomeIcon />
            </div>
          }
          component={Link}
          to="/home"
        />
        <BottomNavigationAction
          label="Decks"
          icon={
            <div style={{ position: 'relative' }}>
              <DecksIcon />
            </div>
          }
          component={Link}
          to="/decks"
        />
        <BottomNavigationAction
          label="Spielen"
          icon={
            <div style={{ position: 'relative' }}>
              <SpielenIcon />
            </div>
          }
          component={Link}
          to="/spielen"
        />
        <BottomNavigationAction
          label="Mehr"
          icon={
            <div style={{ position: 'relative' }}>
              <MoreIcon />
            </div>
          }
          onClick={handleMoreClick}
        />
      </StyledBottomNavigation>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        {user && user.is_admin && (
          <MenuItem onClick={() => handleMenuItemClick('/admin')}>
            <ListItemIcon>
              <AdminIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Admin Portal</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={() => handleMenuItemClick('/anleitung')}>
          <ListItemIcon>
            <ArticleSharpIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Anleitung</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('/settings')}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Einstellungen</ListItemText>
        </MenuItem>
      </Menu>
    </Paper>
  );
};

export default BottomMenu;